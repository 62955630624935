import React, { useEffect, useState, useRef } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import UserAPI from "../../services/User";
import store from "../../redux/store";
import { Organisation } from "../referrals/ReferralUploadWrapper";
import { useGetOrganisationBySlugQuery } from "../../services/OrganisationService";
import { CurrentUser } from "../../services/User";

interface DropdownProps {
  organisation: Organisation | null;
}

const Dropdown: React.FC<DropdownProps> = ({ organisation }) => {
  const { logout } = useAuth0();

  return (
    <div
      className="absolute right-0 top-10 mt-2 w-52 bg-white border border-gray-200 rounded shadow-lg z-10"
      onClick={(e) => e.stopPropagation()} // Prevent clicks inside the dropdown from closing it
    >
      <div className="p-4 text-left border-b border-gray-200 pb-2">
        <p className="text-xs font-medium">Organisation:</p>
        <p className="font-medium">{organisation?.name}</p>
      </div>

      <p 
        className="p-4  text-black cursor-pointer hover:bg-gray-100" 
        onClick={() => logout({ logoutParams: { returnTo: window.location.origin } })}
      >
        Log out
      </p>
    </div>
  );
};

const TopNavBar: React.FC = () => {
  const [currentUser, setCurrentUser] = useState<CurrentUser | null>(null);
  const [organisation, setOrganisation] = useState<Organisation | null>(null);
  const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);
  const { user, getIdTokenClaims } = useAuth0();
  const dropdownRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (currentUser === null && user !== undefined) {
      fetchCurrentUser();
    }
  }, [currentUser, user]);

  const handleOutsideClick = (event: MouseEvent) => {
    const target = event.target as HTMLElement;
    
    if (dropdownRef.current && !dropdownRef.current.contains(target) && !target.closest(".status-dropdown")) {
      setIsDropdownOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  const fetchCurrentUser = async () => {
    const token = await getAccessToken();
    if (user?.sub) {
      const response = await UserAPI.currentUser(user?.sub, token);

      if (response.data) {
        const user = response.data as CurrentUser;
        setCurrentUser(user);
        store.dispatch({ type: "currentUserState", payload: user });
      } else {
        console.error("Fetch current user failed:", response.error);
      }
    }
  };

  const getAccessToken = async () => {
    try {
      const tokenClaims = await getIdTokenClaims();
      const accessToken = tokenClaims?.__raw;
      return accessToken;
    } catch (error) {
      console.error("Error retrieving access token:", error);
    }
  };

  const tenantSlug = currentUser?.tenantSlug;
  const { data, error, isLoading } = useGetOrganisationBySlugQuery(tenantSlug || "", {
    skip: !tenantSlug,
  });

  useEffect(() => {
    if (error) {
      console.error("Failed to fetch organisation", error);
      return;
    }

    if (isLoading) {
      return;
    }

    if (data) {
      setOrganisation(data.data);
    }
  }, [data, error, isLoading]);

  return (
    <section className="py-3 px-6 bg-white status-dropdown" style={{ borderBottom: "1px solid #e4e4e4" }}>
      <nav>
        <div className="relative flex justify-end items-center">
          <div className="flex items-center">
            <div className="mr-3">
              <p className="text-sm">{currentUser?.email}</p>
            </div>

            <button onClick={() => setIsDropdownOpen(!isDropdownOpen)} className="flex items-center">
              <div className="mr-2">
                <img
                  className="w-10 h-10 rounded-full object-cover"
                  src={currentUser?.avatarUrl}
                  alt=""
                />
              </div>
              <span>
                <svg
                  className="text-gray-400"
                  width="10"
                  height="6"
                  viewBox="0 0 10 6"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M9.08335 0.666657C8.75002 0.333323 8.25002 0.333323 7.91669 0.666657L5.00002 3.58332L2.08335 0.666657C1.75002 0.333323 1.25002 0.333323 0.916687 0.666657C0.583354 0.99999 0.583354 1.49999 0.916687 1.83332L4.41669 5.33332C4.58335 5.49999 4.75002 5.58332 5.00002 5.58332C5.25002 5.58332 5.41669 5.49999 5.58335 5.33332L9.08335 1.83332C9.41669 1.49999 9.41669 0.99999 9.08335 0.666657Z"
                    fill="currentColor"
                  ></path>
                </svg>
              </span>
            </button>
            {isDropdownOpen && (
              <div ref={dropdownRef}>
                <Dropdown organisation={organisation}/>
              </div>
            )}
          </div>
        </div>
      </nav>
    </section>
  );
};

export default TopNavBar;