import produce from "immer";
import alertSound from "../../assets/sounds/nice_alert_sound.mp3";
import store from "../../redux/store";
import { Referral } from "../../services/MediaPatient";
import { CurrentUser } from "../../services/User";
import notificationAlertIcon from "../../assets/images/notification-alert.svg";

export default class ReferralListWebSocket {
  private static instance: ReferralListWebSocket;
  private socket: WebSocket | null = null;
  private currentUser: CurrentUser;
  
  constructor(currentUser: CurrentUser) {
    this.currentUser = currentUser;
    this.initializeWebSocket();
  }

  private initializeWebSocket() {
    const protocol = window.location.protocol === "https:" ? "wss:" : "ws:";
    const websocketUrl = `${protocol}${process.env.REACT_APP_API_WS_HOST_URL}/ws/referrals?tenant-slug=${this.currentUser.tenantSlug}`;

    this.socket = new WebSocket(websocketUrl);

    this.socket.onopen = () => {
      console.log("WebSocket connection onopen");
    };

    this.socket.onmessage = this.handleMessage.bind(this);

    this.socket.onclose = () => {
      console.log("WebSocket connection onclose");
    };
  }

  public static getInstance(currentUser: CurrentUser): ReferralListWebSocket {
    if (!ReferralListWebSocket.instance) {
      Notification.requestPermission();
      console.log("new webSocket connection open");
      ReferralListWebSocket.instance = new ReferralListWebSocket(currentUser);
    }
    return ReferralListWebSocket.instance;
  }

  private handleMessage(event: MessageEvent) {
    const data = JSON.parse(event.data);
    const audio = new Audio(alertSound);
    const newReferral = data as Referral;
    audio.play();

    function createAndShowNotification() {
      const notification = new Notification("Elvati - Referral Notification", {
        body: "A new referral has arrived",
        icon: notificationAlertIcon 
      });
  
      // Handle notification click
      notification.onclick = () => {
        window.location.href = `${process.env.REACT_APP_HOST_URL}/referrals/${newReferral.id}`;
        notification.close();
      };
    }

    if (Notification.permission === "granted") {
      createAndShowNotification();
    } else if (Notification.permission !== "denied") {
      Notification.requestPermission().then((permission) => {
        if (permission === "granted") {
          createAndShowNotification();
        }
      });
    }

    const nextState = produce(store.getState().referrals, (draftState: Referral[]) => {
      draftState.splice(0, 0, newReferral);
    });

    store.dispatch({ type: "referralsState", payload: nextState });
  }

  public close() {
    this.socket?.close();
  }
}


