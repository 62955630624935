import React, { useEffect, useState } from "react";
import ClientHeader from "./ClientHeader";
import { Organisation } from "../ReferralUploadWrapper";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import BookingPreferenceService from "../../../services/BookingPreferenceService";
import { useGetOrganisationBySlugQuery } from "../../../services/OrganisationService";

interface Params {
  organisation: Organisation;
}

enum PreferredTime {
  EarlyMorning = "Early Morning",
  LateMorning = "Late Morning",
  EarlyAfternoon = "Early Afternoon",
  LateAfternoon = "Late Afternoon",
}

const BookingPreference: React.FC = () => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { slug, referralId } = useParams<{ slug: string; referralId: string }>();
  const location = useLocation();
  const [currentOrganisation, setCurrentOrganisation] = useState<Organisation | null>(null);
  const [loading, setLoading] = useState<boolean>(true);

  const [preferredDate, setPreferredDate] = React.useState<string>("");
  const [preferredTime, setPreferredTime] = React.useState<PreferredTime | "">("");
  const navigate = useNavigate();

  useEffect(() => {
    const state = location.state as Params | null;
    
    if (state && state.organisation) {
      setCurrentOrganisation(state.organisation);
      setLoading(false);
    } else {
      const fetchOrganisation = async () => {
        if (!slug) {
          return;
        }
        try {
          setLoading(true);
          const { data, error, isLoading } = useGetOrganisationBySlugQuery(slug);

          if (error) {
            console.error("Failed to fetch organisation", error);
            return;
          }
    
          if (isLoading) {
            return;
          }
    
          if (!data) {
            console.error("No organisation data found");
            return;
          }
    
          setCurrentOrganisation(data?.data);
          setLoading(false);
        } catch (error) {
          console.error("Failed to fetch organisation:", error);
        }
      };

      fetchOrganisation();
    }
  }, [location.state, slug]);

  const handleSubmit = async () => {
    if (!preferredDate || !preferredTime) {
      alert("Please fill in the date and time or press the 'Skip' button.");
      return;
    }

    if (!slug || !referralId) {
      return;
    }

    const response = await BookingPreferenceService.create({
      tenantId: slug,
      referralId,
      date: preferredDate,
      time: preferredTime,
    });

    if (response.data) {
      navigate(`/${slug}/referrals/${referralId}/success`);
    } else {
      alert("Error submitting booking preference. Please try again.");
    }
  };

  const handleDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputDate = e.target.value;
    const today = new Date();
    const selectedDate = new Date(inputDate);
  
    today.setHours(0, 0, 0, 0);
  
    if (selectedDate < today) {
      alert("The preferred date must be today or in the future.");
    } else {
      setPreferredDate(inputDate);
    }
  };

  return (
    <div className="content-center">
      {
        currentOrganisation !== null && !loading && <div className="mx-auto max-w-[600px]">
          <div className="pb-8">
            <ClientHeader organisation={currentOrganisation} />
          </div>

          <div className="px-3 pt-4">
            <div className="mb-4 border-l-4 bg-gray-100 border-blue-500 text-blue-900 px-4 py-3" role="alert">
              <div className="flex">
                <div className="py-1"><svg className="fill-current h-6 w-6 text-blue-500 mr-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z"/></svg></div>
                <div>
                  <p className="text-lg font-bold">Preferred Time</p>
                  <p className="text-sm">Please choose a preferred date and time for your procedure.</p>
                </div>
              </div>
            </div>

            <label className="block pb-3">
              <span className="block font-medium text-gray-700 pb-1">Preferred Date</span>
              <input
                className="w-full border rounded p-3 text-gray-600 text-lg input-phone-number bg-white"
                value={preferredDate}
                onChange={(e) => { handleDateChange(e); }}
                type="date"
                id="preferred_date"
                name="preferred_date"
              />
            </label>

            <label className="block pb-4">
              <span className="block font-medium text-gray-700 pb-1">Preferred Time</span>
              <select
                className="w-full border rounded p-3 text-gray-600 text-lg input-phone-number bg-white"
                value={preferredTime}
                onChange={(e) => setPreferredTime(e.target.value as PreferredTime)}
                id="preferred_time"
                name="preferred_time"
              >
                <option value="">Select a preferred time</option>
                {Object.values(PreferredTime).map((time) => (
                  <option key={time} value={time}>
                    {time}
                  </option>
                ))}
              </select>
            </label>

            <div className="flex">
              <button 
                className="block w-full font-bold py-3 px-4 border border-gray rounded mr-2"
                onClick={() => navigate(`/${slug}/referrals/${referralId}/success`)}
              >
                Skip
              </button>
              <button 
                className="block w-full bg-green-500 hover:bg-green-700 text-white font-bold py-3 px-4 rounded"
                onClick={handleSubmit}
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      }

      {
        loading && <div className="flex justify-center pt-8">
          <span className="inline-block h-8 w-8 animate-spin text-gray-900 rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]" />
        </div>
      }
    </div>
  );
};

export default BookingPreference;
