import React, { useEffect, useState } from "react";
import PhoneInput from "react-phone-number-input";
import { CurrentUser } from "../../services/User";
import "react-phone-number-input/style.css";
import Modal from "react-modal";
import ActivityFeed from "../activity/ActivityFeed";
import { useGetOrganisationBySlugQuery } from "../../services/OrganisationService";
import { Organisation } from "./ReferralUploadWrapper";

export interface RequestReferralParams {
  isOpen: boolean;
  accessToken: string | null;
  closeModal: () => void;
  currentUser: CurrentUser;
}

export const RequestReferral: React.FC<RequestReferralParams> = (params: RequestReferralParams) => {
  const [mobilePhoneNumber, setMobilePhoneNumber] = useState<string | undefined>("");
  const [isSending, setIsSending] = useState<boolean>(false);
  const [buttonText, setButtonText] = useState<string>("Send Text Message");
  const [, setButtonColor] = useState<string>("blue");
  const [organisation, setOrganisation] = useState<Organisation | null>(null);
  const textMessageBody = `Hello from ${organisation?.name || titleizeString(params.currentUser.tenantSlug)}, we would be delighted if you could kindly share your referral with us. To make the process effortless, simply follow this link: https://elvati.com/${params.currentUser.tenantSlug}/referral_upload?mobile_phone=${mobilePhoneNumber}. Once there, you can easily take a photo with your phone, or upload an image.`;

  const { data, error, isLoading } = useGetOrganisationBySlugQuery(params.currentUser.tenantSlug);

  useEffect(() => {
    if (error) {
      console.error("Failed to fetch organisation", error);
      return;
    }

    if (isLoading) {
      return;
    }

    if (!data) {
      console.error("No organisation data found");
      return;
    }

    setOrganisation(data?.data);
  }, [data, error, isLoading]);
  

  function titleizeString(inputString: string): string {
    const titleizedWords = inputString.split("_").map((word) => {
      return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    });
  
    return titleizedWords.join(" ");
  }

  const sendTextMessage = async () => {
    setIsSending(true);
    setButtonText("Sending text message");
    const textMessageParas = {
      mobilePhoneNumber: mobilePhoneNumber,
      textMessageBody: textMessageBody,
    };

    const response = await fetch(`${process.env.REACT_APP_API_HOST_URL}/referrals/request`, {
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${params.accessToken}`,
        "Tenant-Slug": params.currentUser.tenantSlug,
      },
      method: "POST",
      body: JSON.stringify(textMessageParas),
    });

    setIsSending(false);

    if (response.ok) {
      setButtonText("Message successfully sent");
      setButtonColor("green");
      setTimeout(() => {
        setButtonColor("blue");
        setButtonText("Send Text Message");
      }, 2500);

      return { data: response.body, error: null };
    } else {
      setButtonText("Message failed");
      return { data: null, error: "Upload failed" };
    }
  };

  const handleMobilePhoneNumberChange = (value: string | undefined) => {
    console.log("value", value);
    setMobilePhoneNumber(value);
  };

  return (
    <Modal
      isOpen={params.isOpen}
      onRequestClose={params.closeModal}
      style={modalStyles}
      contentLabel="Example Modal"
    >
      <div className="relative transform overflow-hidden text-left transition-all">
        <div className="grid grid-cols-1 sm:grid-cols-12">
          <div className="sm:col-span-8">
            <div className="p-8">
              <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                <h3 className="text-xl font-semibold leading-6 text-gray-900" id="modal-title">Request Referral</h3>
                <div className="mt-2 mb-6">
                  <p className="text-gray-500">Send a text message to your patient so they can upload their referral.</p>
                </div>
                <form>
                  <label className="block pb-3">
                    <span className="block text-sm font-medium text-gray-700 pb-2">Mobile phone number</span>
                    <PhoneInput
                      defaultCountry="AU"
                      value={mobilePhoneNumber}
                      onChange={handleMobilePhoneNumberChange}
                      className="w-full border rounded p-1 px-3 text-gray-600 text-lg input-phone-number"
                      placeholder="eg 0411 222 333"
                    />
                  </label>
                  {
                    !organisation && (
                      <div className="flex justify-center pt-8">
                        <span className="inline-block h-8 w-8 animate-spin text-gray-900 rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]" />
                      </div>
                    )
                  }

                  {
                    organisation && (
                      <label className="block">
                      <span className="block text-sm font-medium text-gray-700 pb-2">Message</span>
                      <textarea
                          rows={8}
                          className="w-full border rounded p-1 px-3 text-gray-600 text-lg"
                          disabled={true}
                          value={textMessageBody}
                        />
                      </label>
                    )
                  }
                </form>
              </div>
            </div>
            <div className="px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
              <button
                type="button"
                onClick={() => sendTextMessage()}
                className={"inline-flex w-full justify-center rounded-md bg-blue-600 px-3 py-2 font-semibold text-white shadow-sm sm:ml-3 sm:w-auto"}
              >
                {isSending && (
                  <span className="inline-block h-6 w-6 mr-2 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]" />
                )}
                {buttonText}
              </button>
              <button
                type="button"
                onClick={() => params.closeModal()}
                className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
              >
                Cancel
              </button>
            </div>
          </div>
          <div className="sm:col-span-4 border border-gray-100 bg-gray-100">
            <ActivityFeed />
          </div>
        </div>
      </div>
    </Modal>
  );
};


export default RequestReferral;


const modalStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    padding:"0px",
    border:"none",
    boxShadow: "#b2b2b2 -10px 10px 20px;",
    borderRadius: "10px",
  },
  overlay: {
    zIndex: 20,
    backgroundColor: "rgba(0,0,0,0.15)",
  }
};