import React, { useEffect, useState } from "react";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import { useGetOrganisationBySlugQuery } from "../../../services/OrganisationService";
import ClientHeader from "./ClientHeader";
import { Organisation } from "../ReferralUploadWrapper";
import greenTickGif from "../../../assets/images/green_tick_animation.gif";

const SuccessfulRegistration: React.FC = () => {
  const navigate = useNavigate();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { slug, referralId } = useParams<{ slug: string; referralId: string }>();
  const location = useLocation();
  const [currentOrganisation, setCurrentOrganisation] = useState<Organisation | null>(null);
  const [loading, setLoading] = useState<boolean>(true);

  const { data, error, isLoading } = useGetOrganisationBySlugQuery(slug ?? "");

  useEffect(() => {
    const state = location.state as { organisation?: Organisation } | null;

    if (state && state.organisation) {
      setCurrentOrganisation(state.organisation);
      setLoading(false);
    } else if (slug) {
      if (error) {
        console.error("Failed to fetch organisation", error);
        setLoading(false);
      }

      if (!isLoading && data) {
        setCurrentOrganisation(data.data);
        setLoading(false);
      }
    } else {
      setLoading(false);
    }
  }, [location.state, slug, data, error, isLoading]);

  const directToCompanyWebsite = () => {
    if (!currentOrganisation) {
      return;
    }
    window.location.href = currentOrganisation.website;
  };

  const uploadAnother = () => {
    if (!currentOrganisation) {
      return;
    }

    navigate(`/${currentOrganisation.slug}/referral_upload`);
  };

  return (
    <div className="content-center">
      {
        currentOrganisation !== null && !loading && <div className="mx-auto max-w-[600px]">
          <div className="pb-4">
            <ClientHeader organisation={currentOrganisation} />
          </div>

          <div className="px-3">
              <div className="flex justify-center">
                <img src={greenTickGif} alt="" className="w-8/12" />
              </div>  
              <p className="text-2xl font-bold pb-4 text-gray-800 text-center">Success!</p>
              <p className="text-base pb-4 text-gray-800 text-center">We have received your details. One of our staff members will call you shortly.</p>

              <div className="flex pt-4">
                <button
                  className="block w-full font-bold py-3 px-4 border border-gray rounded mr-2"
                  onClick={() => uploadAnother()}
                >
                  <p className="relative z-10">Upload Another</p>
                </button>
                <button
                  className="block w-full bg-green-500 hover:bg-green-700 text-white font-bold py-3 px-4 rounded"
                  onClick={() => directToCompanyWebsite()}
                >
                  <p className="relative z-10">Exit</p>
                </button>
              </div>
            </div>
        </div>
      }

      {
        loading && <div className="flex justify-center pt-8">
          <span className="inline-block h-8 w-8 animate-spin text-gray-900 rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]" />
        </div>
      }


      
    </div>
  );
};

export default SuccessfulRegistration;
