import React, { useEffect, useState } from "react";
import { useParams, Navigate } from "react-router-dom";
import ReferralUpload from "./UploadReferral";
import { useGetOrganisationBySlugQuery } from "../../services/OrganisationService";

export interface Organisation {
  name: string;
  email: string;
  slug: string;
  country: string;
  website: string;
  address: string;
  landline: string;
  logoUrl?: string;
  metadata: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    [key: string]: any; // Define the structure of metadata as a key-value pair
  };
}

const ReferralUploadWrapper = () => {
  const { slug } = useParams<{ slug?: string }>();
  const [organisation, setOrganisation] = useState<Organisation | null>(null);
  const [loading, setLoading] = useState(true);

  if (!slug) {
    return <div>Invalid slug</div>;
  }

  const { data, error, isLoading } = useGetOrganisationBySlugQuery(slug);

  useEffect(() => {
    if (error) {
      console.error("Failed to fetch organisation", error);
      setLoading(false);
      return;
    }

    if (isLoading) {
      return;
    }

    if (!data) {
      console.error("No organisation data found");
      setLoading(false);
      return;
    }

    setOrganisation(data?.data);
    setLoading(false);
  }, [data, error, isLoading]);

  if (loading) {
    return (
      <div className="flex flex-col items-center justify-center min-h-screen">
        <p className="text-xl mb-4">Loading</p>
        <span className="inline-block h-8 w-8 animate-spin text-gray-900 rounded-full border-4 border-solid border-current border-r-transparent" />
      </div>
    );
  }

  if (!organisation) {
    return <Navigate to="/" />;
  }

  return <div>
    {
      organisation && <ReferralUpload organisation={organisation} />
    }
  </div>;
};

export default ReferralUploadWrapper;
