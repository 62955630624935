import React, { useEffect, useRef, useState } from "react";
import ImageDisplay from "../../ImageDisplay";
import { Organisation } from "../ReferralUploadWrapper";

export interface Params {
  organisation: Organisation;
}

const ClientHeader: React.FC<Params> = (props: Params) => {
  const [showPopup, setShowPopup] = useState<boolean>(false);
  const [popUpMessage, setPopUpMessage] = useState<string>("");
  const popupRef = useRef(null);

  const handleClickOutside = (event: MouseEvent) => {
    if (popupRef.current && !(popupRef.current as HTMLDivElement).contains(event.target as Node)) {
      setShowPopup(false);
    }
  };

  useEffect(() => {
    if (showPopup) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showPopup]);

  return(
    <div className="relative h-24" style={{ backgroundColor: props.organisation.metadata.profileHeaderBgColor || "#e3e4e4" }}>
      { props.organisation.metadata.profileHeaderImageUrl && <ImageDisplay imageKey={props.organisation.metadata.profileHeaderImageUrl} className="w-full h-full object-cover" /> }
      { props.organisation.metadata.profileHeaderGradientCss && <div className="absolute inset-0 bg-gradient-to-t from-black to-transparent"></div> }
      
      <div className="absolute w-20 h-20 border border-gray-100" style={{ left: "10px", top: "45px" }}>
        { props.organisation.logoUrl && <ImageDisplay imageKey={props.organisation.logoUrl} className="" /> }
      </div>
      <p className="absolute text-xl font-bold pb-1" style={{ left: "100px", bottom: "0px", color: props.organisation.metadata.profileHeaderFontColor || "black" }}>
        { props.organisation.name }
      </p>
      <div className="absolute" style={{ left: "100px", bottom: "-27px" }}>
        <button 
          className="pr-2 text-sm text-blue-600" 
          onClick={() =>  window.location.href = props.organisation.website }
        >
            Website
        </button>
        <button 
          className="pr-2 text-sm text-blue-600"
          onClick={() => {
            setShowPopup(true);
            setPopUpMessage(props.organisation.address);
          }}
        >Address</button>
        <button 
          className="pr-2 text-sm text-blue-600"
          onClick={() => {
            setShowPopup(true);
            setPopUpMessage(props.organisation.email);
          }}
        >Email</button>
        <button 
          className="text-sm text-blue-600"
          onClick={() => {
            setShowPopup(true);
            setPopUpMessage(props.organisation.landline);
          }}
        >Phone</button>
      </div>

      {showPopup && (
        <div ref={popupRef} className="absolute mt-2 w-64 p-4 bg-white border border-gray-300 shadow-lg z-50 rounded-lg ml-2" style={{ left: "0" }}>
          <button
            className="absolute top-1 right-2 text-gray-600 text-xl"
            onClick={() => setShowPopup(false)}
          >
            &times;
          </button>
          <p className="text-sm text-gray-700 pt-2">
            { popUpMessage }
          </p>
        </div>
      )}
    </div>
  );
};

export default ClientHeader;
