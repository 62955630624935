import { CurrentUser } from "./User";
import { Patient } from "./MediaPatient";

export interface ReferralStatusResponse {
  data: ReferralStatus[] | null;
  error: string | null;
}

export interface ReferralStatus extends Patient {
  id: string;
  name: string;
  isDefault: boolean;
  index: number;
  metadata: {
    color: string;
    iconName: string;
  };
  createdAt: string;
  updatedAt: string;
}

export default class ReferralStatusService {
  static async index(currentUser: CurrentUser, accessToken: string | undefined): Promise<ReferralStatusResponse> {
    const response = await fetch(
      `${process.env.REACT_APP_API_HOST_URL}/referral_status`, {
      headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${accessToken}`,
          "Tenant-Slug": currentUser.tenantSlug,
        },
        method: "GET",
      }
    );

    if (response.ok) {
      const responseData = await response.json();
      const referralStates: ReferralStatus[] = responseData.data;
      return { data: referralStates, error: null };
    } else {
      return { data: null, error: "Fetching referral states failed" };
    }
  }
}