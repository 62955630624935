import React, { useEffect, useState } from "react";
import AppLayout from "../layout/AppLayout";
import { useParams } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import MediaPatientAPI, { Referral } from "../../services/MediaPatient";
import UserService, { User, UserResponse } from "../../services/UserService";
import store from "../../redux/store";
import UserAPI, { CurrentUser } from "../../services/User";
import ReferralInfo from "./ReferralInfo";
import ReferralStatusService, { ReferralStatus, ReferralStatusResponse } from "../../services/ReferralStatusService";

const ReferralShow: React.FC = ()  => {
  const [referral, setReferral] = useState<Referral | null>(null);
  const referralId = useParams().referralId as string; 
  const { getIdTokenClaims } = useAuth0();
  const [currentUser, setCurrentUser] = useState<CurrentUser | null>(null);
  const [loadingReferral, setLoadingReferral] = useState<boolean>(true);
  const { user } = useAuth0();
  const [referralStatuses, setReferralStatuses] = useState<ReferralStatus[] | null>(null);
  const [users, setUsers] = useState<User[] | null>(null);
  
  useEffect(() => {
    onLoad();
  });

  const onLoad = async () => {
    if (currentUser === null) {
      const user = await fetchCurrentUser();
      if (user) {
        fetchReferral(user);
        requestReferralStatuses(user);
        requestUsers(user);
      }
    }
  };

  const requestReferralStatuses = async (user: CurrentUser) => {
    const token = await getAccessToken();
    const response: ReferralStatusResponse = await ReferralStatusService.index(user, token);
    if (response.data) {
      setReferralStatuses(response.data);
      store.dispatch({ type: "referralStatuses", payload: response.data });
    } else {
      console.error("Fetch current user failed:", response.error);
    }
  };

  const requestUsers = async (user: CurrentUser) => {
    const token = await getAccessToken();
    const response: UserResponse = await UserService.index(user, token);
    if (response.data) {
      setUsers(response.data);
      store.dispatch({ type: "users", payload: response.data });
    } else {
      console.error("Fetch current user failed:", response.error);
    }
  };

  const fetchReferral = async (user: CurrentUser) => {
    const token = await getAccessToken();
    const response = await MediaPatientAPI.get(user, token, referralId);

    if (response.data) {
      const referral = response.data as Referral;
      setLoadingReferral(false);
      setReferral(referral as Referral);
    } else {
      console.error("Failed to fetch referral", response.error);
      setLoadingReferral(false);
    }
  };

  const fetchCurrentUser = async () => {
    const token = await getAccessToken();
    if (user?.sub) {
      const response = await UserAPI.currentUser(user?.sub, token);
  
      if (response.data) {
        const user = response.data as CurrentUser;
        setCurrentUser(user);
        store.dispatch({ type: "currentUserState", payload: user });
        return user;
      } else {
        console.error("Fetch current user failed:", response.error);
      }
    }
  };

  const getAccessToken = async () => {
    try {
      const tokenClaims = await getIdTokenClaims();
      const accessToken = tokenClaims?.__raw;
      return accessToken;
    } catch (error) {
      console.error("Error retrieving access token:", error);
    }
  };

  return(
    <AppLayout>
      <section className="px-12">
        <section className="w-full mx-auto pt-8">
          {
            loadingReferral && <div>Loading...</div>
          }

          {
            !loadingReferral && referral &&
            <div className="bg-white rounded-lg">
              <ReferralInfo 
                referralStatuses={referralStatuses}
                users={users}
                referral={referral} 
              />
            </div>
          }
        </section>
      </section>
    </AppLayout>
  );
};

export default ReferralShow;
