/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useRef, useState } from "react";
import arrowDownIcon from "../../assets/icons/arrowDown.svg";
import ReferralStatusService, { ReferralStatus, ReferralStatusResponse } from "../../services/ReferralStatusService";
import { CurrentUser } from "../../services/User";
import store from "../../redux/store";
import { ReferralOrigins } from "../../services/MediaPatient";
import { User } from "../../services/UserService";
import moment from "moment";

interface ReferralListFiltersProps {
  resetFilters: () => void;
  currentUserIdFilterName: () => void;
  currentUser: CurrentUser | null;
  accessToken: string | undefined
  currentStatusFilter: string | null;
  statusFilterChange: (filterName: string) => void,
  currentOriginFilter: ReferralOrigins | null;
  originFilterChange: (filterName: ReferralOrigins | null) => void,
  currentAssignedToFilterName: string | null;
  assignedToChange: (userId: string | null) => void,
  dateOfBirth: string | null;
  handleDateChange: (date: string) => void;
}

export interface OptionParam {
  name: string;
  value: string | null;
}

const ReferralListFilters: React.FC<ReferralListFiltersProps> = ({
  resetFilters, 
  currentUserIdFilterName,
  currentUser,
  accessToken,
  currentStatusFilter,
  statusFilterChange,
  currentOriginFilter,
  originFilterChange,
  currentAssignedToFilterName,
  assignedToChange,
  dateOfBirth,
  handleDateChange,
}) => {
  const [referralStatuses, setReferralStatuses] = useState<ReferralStatus[] | null>(null);
  const currentStatusFilterName = currentStatusFilter === "All" ? "All" : store.getState().referralStatuses.find((status: ReferralStatus) => status.name === currentStatusFilter)?.name;
  const currentOriginFilterName = currentOriginFilter === null ? "All" : currentOriginFilter;
  const [isStatusOpen, setIsStatusOpen] = useState<boolean>(false);
  const [isOriginOpen, setIsOriginOpen] = useState<boolean>(false);
  const [isAssignedToOpen, setIsAssignedToOpen] = useState<boolean>(false);
  const [isDateOfBirthOpen, setIsDateOfBirthOpen] = useState<boolean>(false);
  const inputRef = useRef<HTMLInputElement>(null);


  useEffect(() => {
    if (store.getState().referralStatuses.length == 0) {
      requestReferralStatuses();
    }
  });
  
  async function requestReferralStatuses() {
    if (currentUser === null|| accessToken === undefined) {
      return;
    }

    const response: ReferralStatusResponse = await ReferralStatusService.index(currentUser, accessToken);
    if (response.data) {
      setReferralStatuses(response.data);
      store.dispatch({ type: "referralStatusesState", payload: response.data });
    } else {
      console.error("Fetch current user failed:", response.error);
    }
  }

  const statusList = [
    { name: "All", value: null }, 
    ...(referralStatuses ? referralStatuses.sort((a: ReferralStatus) => a.index).map((rs: ReferralStatus) => ({ name: rs.name, value: rs.name })) : [])
  ];

  const originList = [
    { name: "All", value: null}, 
    ...Object.entries(ReferralOrigins).map(([key, value]) => ({ name: key, value }))
  ];

  const users: User[] = store.getState().users || [];
  const userList = [
    { name: "All", value: null}, 
    ...users.map((user: User) => ({ name: fullName(user), value: user.id })).sort((a, b) => a.name.localeCompare(b.name))
  ];

  const handleOutsideClick = (event: MouseEvent) => {
    const target = event.target as HTMLElement;
    if (
      isStatusOpen && !target.closest(".status-dropdown") ||
      isOriginOpen && !target.closest(".origin-dropdown") ||
      isAssignedToOpen && !target.closest(".assigned-to-dropdown") ||
      isDateOfBirthOpen && !target.closest(".dateOfBirth-dropdown")
    ) {
      setIsStatusOpen(false);
      setIsOriginOpen(false);
      setIsAssignedToOpen(false);
      setIsDateOfBirthOpen(false);
    }
  };


  useEffect(() => {
    document.addEventListener("click", handleOutsideClick);
    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, [isStatusOpen, isOriginOpen, isAssignedToOpen, isDateOfBirthOpen]);

  return(
    <div className="flex items-center">
      <div className="relative pr-2 status-dropdown">
        <button
          className="border border-gray-400 rounded-lg p-2 bg-white flex items-center rounded text-sm font-medium capitalize leading-normal text-primary"
          type="button"
          onClick={() => setIsStatusOpen(!isStatusOpen)}
        >
          <span className="flex relative font-bold">
            <p className=" mr-2">Status:</p>
            <p className="text-blue-600">{currentStatusFilterName}</p>

            <img src={arrowDownIcon} alt="Arrow Down" className="w-5 h-5" />
          </span>
        </button>

        <ul
          className={`border border-gray-600 absolute z-[1000] float-left mt-1 ${isStatusOpen ? "" : "hidden"} min-w-max list-none overflow-hidden rounded-lg border-none bg-white bg-clip-padding text-base shadow-lg data-[twe-dropdown-show]:block dark:bg-surface-dark`}
          aria-labelledby="dropdownMenuButton19"
          data-twe-dropdown-menuRef
        >
          {
            statusList.map((status: OptionParam) => (
              <li key={status.name} className="cursor-pointer ">
                <div 
                  className="block py-2 px-4 capitalize border border-b"
                  onClick={() => {
                    statusFilterChange(status.name);
                    setIsStatusOpen(false);
                  }}
                >
                  {status.name}
                </div>
              </li>
            ))
          }
        </ul>
      </div>      

      <div className="relative pr-2 origin-dropdown">
        <button
          className="border border-gray-400 rounded-lg p-2 bg-white flex items-center rounded text-sm font-medium capitalize leading-normal text-primary"
          type="button"
          onClick={() => {
            console.log("hit origin open", isStatusOpen);
            setIsOriginOpen(!isOriginOpen);
          }}
        >
          <span className="flex relative font-bold">
            <p className=" mr-2">Origin:</p>
            <p className="text-blue-600">
              {originName(currentOriginFilterName)}
            </p>

            <img src={arrowDownIcon} alt="Arrow Down" className="w-5 h-5" />
          </span>
        </button>

        <ul
          className={`border border-gray-600 absolute z-[1000] float-left mt-1 ${isOriginOpen ? "" : "hidden"} min-w-max list-none overflow-hidden rounded-lg border-none bg-white bg-clip-padding text-base shadow-lg data-[twe-dropdown-show]:block dark:bg-surface-dark`}
          aria-labelledby="dropdownMenuButton19"
          data-twe-dropdown-menuRef
        >
          {
            originList.map((origin: OptionParam) => (
              <li key={origin.name} className="cursor-pointer ">
                <div 
                  className="block py-2 px-4 capitalize border border-b"
                  onClick={() => {
                    originFilterChange(origin.value as ReferralOrigins);
                    setIsOriginOpen(false);
                  }}
                >
                  {originName(origin.name)}
                </div>
              </li>
            ))
          }
        </ul>
      </div>

      <div className="relative assigned-to-dropdown pr-2">
        <button
          className="border border-gray-400 rounded-lg p-2 bg-white flex items-center rounded text-sm font-medium capitalize leading-normal text-primary"
          type="button"
          onClick={() => 
            setIsAssignedToOpen(!isAssignedToOpen)
          }
        >
          <span className="flex relative font-bold">
            <p className=" mr-2">Assigned To:</p>
            <p className="text-blue-600">
              {currentAssignedToFilterName}
            </p>

            <img src={arrowDownIcon} alt="Arrow Down" className="w-5 h-5" />
          </span>
        </button>

        {
          isAssignedToOpen && <ul
          className="border border-gray-600 absolute z-[1000] float-left mt-1 min-w-max list-none overflow-hidden rounded-lg border-none bg-white bg-clip-padding text-base shadow-lg data-[twe-dropdown-show]:block dark:bg-surface-dark"
          aria-labelledby="dropdownMenuButton19"
          data-twe-dropdown-menuRef
          >
            {
              userList.map((assignedTo: OptionParam) => (
                <li key={assignedTo.name} className="cursor-pointer ">
                  <div 
                    className="block py-2 px-4 capitalize border border-b"
                    onClick={() => {
                      assignedToChange(assignedTo.value as ReferralOrigins);
                      setIsAssignedToOpen(false);
                    }}
                  >
                    {originName(assignedTo.name)}
                  </div>
                </li>
              ))
            }
          </ul>
        }
      </div>

      <div className="relative dateOfBirth-dropdown">
        <button
          className="border border-gray-400 rounded-lg p-2 bg-white flex justify-between items-center text-sm font-medium capitalize leading-normal text-primary"
          type="button"
          onClick={() => {
            setIsDateOfBirthOpen(!isDateOfBirthOpen);
            if (!isDateOfBirthOpen) {
              setTimeout(() => {
                document.getElementById("birthday")?.focus();
              }, 10);
            }
          }}
        >
          <span className="font-bold mr-2">DOB:</span>
          <span className="flex items-center">
            <p className="text-blue-600 mr-1 font-bold">
              { dateOfBirth ? moment(dateOfBirth).format("D/M/YYYY") : "N/A" }
            </p>

            {
              dateOfBirth && (
                <p className="mr-2 text-yellow-600" onClick={() => handleDateChange("")}>Clear</p>
              )
            }
            <img src={arrowDownIcon} alt="Arrow Down" className="w-5 h-5" />
          </span>
        </button>

        {
          isDateOfBirthOpen && <input
            className="w-full border border-gray-400 rounded-lg p-1 mt-2 px-3 text-gray-600 text-lg input-phone-number bg-white absolute z-[1000] float-left"
            value={dateOfBirth || ""}
            style={{ minWidth: "170px" }}
            onChange={(e) => {
              handleDateChange(e.target.value);
            }}
            type="date"
            id="birthday"
            name="birthday"
            title="Filter by date of birth."
          />
        }
      </div>

      <p className="text-sm font-bold text-blue-600 pl-2 cursor-pointer" onClick={() => resetFilters()}>Reset</p>       
    </div>
  );
};

const originName = (origin: string) => {
  if (origin === ReferralOrigins.patient_upload_image_client) {
    return "Text Message";
  }
  if (origin === ReferralOrigins.company_website) {
    return "Company Website";
  }
  if (origin === "apollo_client") {
    return "Apollo Client";
  }
  return origin;
};

export const fullName = (user: User) => {
  if (user.firstName || user.lastName) {
    return `${user.firstName} ${user.lastName}`;
  } else {
    return "N/A";
  }
};

export default ReferralListFilters;