import React from "react";
import "react-phone-number-input/style.css";
import { Referral } from "../../services/MediaPatient";
import Modal from "react-modal";
import ReferralInfo from "./ReferralInfo";
import { User } from "../../services/UserService";
import store from "../../redux/store";

export interface ReferralShowModalParams {
  isOpen: boolean;
  closeModal: () => void;
  referral: Referral | null;
  users: User[] | null;
}

export const ReferralShowModal: React.FC<ReferralShowModalParams> = (params: ReferralShowModalParams) => {
  const referralStatuses = store.getState().referralStatuses;
  return (
    <Modal
      isOpen={params.isOpen}
      onRequestClose={params.closeModal}
      style={modalStyles}
      contentLabel="Example Modal"
    >
      <div>
        {
          params.referral && (
            <ReferralInfo 
              referralStatuses={referralStatuses}
              referral={params.referral}
              users={params.users}
            />
          )
        }
      </div>
    </Modal>
  );
};

export default ReferralShowModal;

const modalStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    height: "90%",
    hight: "90%",
    width: "90%",
    transform: "translate(-50%, -50%)",
    padding:"0px",
    boxShadow: "#b2b2b2 -10px 10px 20px;",
    borderRadius: "10px",
  },
  overlay: {
    zIndex: 20,
    backgroundColor: "rgba(0,0,0,0.15)",
  }
};
