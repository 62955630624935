import React, { useState, useEffect } from "react";
import ActivityLogsService, { ActivityLog } from "../../services/ActivityLogsService";
import { useAuth0 } from "@auth0/auth0-react";
import moment from "moment";
import PhoneInput from "react-phone-number-input";
import InfiniteScroll from "react-infinite-scroller";

const ActivityFeed: React.FC = () => {
  const [expanded, setExpanded] = useState(false);
  const [activityLogs, setActivityLogs] = useState<ActivityLog[]>([]);
  const [reference, setReference] = useState<string | undefined>("");
  const [isLoading, setIsLoading] = useState(true);
  const { getIdTokenClaims } = useAuth0();
  const [page, setPage] = useState(0);
  const [totalCount, setTotalCount] = useState(0);

  useEffect(() => {
    if (activityLogs.length === 0 && reference === "") {
      fetchActivityLogs(page);
    }
  }, [page, reference]);

  const fetchActivityLogs = async (pageNumber: number) => {
    setIsLoading(true);
    const token = await getAccessToken();
    if (!token) {
      return console.error("No access token available");
    }

    const referenceParam = reference ? encodeURIComponent(reference) : null;

    const response = await ActivityLogsService.index(
      token, 
      "SmsRequestReferral",
      referenceParam,
      10,
      pageNumber
    );
    if (response.data) {
      setIsLoading(false);
      const newActivityLogs = response.data.activityLogs || [];
      setTotalCount(response.data.totalCount);
      
      if (newActivityLogs.length > 0) {
        setActivityLogs(prevActivityLogs => {
          const uniqueLogs = newActivityLogs.filter(newLog => !prevActivityLogs.some(prevLog => prevLog.id === newLog.id));
          return prevActivityLogs.concat(uniqueLogs);
        });
      }
    } else {
      setIsLoading(false);
      console.error("Fetch activity logs failed:", response.error);
    }
  };

  const getAccessToken = async () => {
    try {
      const tokenClaims = await getIdTokenClaims();
      const accessToken = tokenClaims?.__raw;
      return accessToken;
    } catch (error) {
      console.error("Error retrieving access token:", error);
    }
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      setPage(0);
      setActivityLogs([]);
      fetchActivityLogs(page);
    }
  };

  const toggleExpanded = () => {
    setExpanded(!expanded);
  };

  const loadMore = () => {
    if (!isLoading) {
      const nextPage = page + 1;
      setPage(nextPage);
      fetchActivityLogs(nextPage);
    }
  };

  const hasMore = () => {
    const hasMore = activityLogs.length < totalCount;
    return hasMore;
  };

  return (
    <div className="max-w-sm">
      <p className="px-2 py-1 text-gray-500 px-3 py-3">Recent Requests</p>
      <div className="relative w-full">
        <PhoneInput
          defaultCountry="AU"
          value={reference}
          onChange={setReference}
          onKeyDown={handleKeyDown}
          style={{ width: "-webkit-fill-available" }}
          className="block p-2.5 w-full text-sm mr-3 ml-3 bg-gray-50 rounded-lg border border-gray-200 mb-3 input-phone-number"
          placeholder="Search: eg 0411 222 333"
        />
        <button 
          type="submit"
          style={{right: "13px"}}
          onClick={() => {
            setActivityLogs([]);
            fetchActivityLogs(0);
          }} 
          className="absolute top-0 right-0 p-2.5 text-sm font-medium h-full text-gray-400 rounded-r-lg"
        >
          <svg className="w-4 h-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
            <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"/>
          </svg>
          <span className="sr-only">Search</span>
        </button>
      </div>
      <div className="overflow-y-auto" style={{maxHeight: "440px"}}>
        <InfiniteScroll
          pageStart={0}
          loadMore={() => loadMore()}
          hasMore={hasMore()}
          loader={<div className="loader" key={0}>Loading ...</div>}
          useWindow={false}
        >
          { activityFeed(activityLogs, expanded, toggleExpanded) }
        </InfiniteScroll>
      </div>
      {isLoading && (
        <div className="flex justify-center" style={{width: "384px"}}>
          <span className="text-blue-500 inline-block h-6 w-6 mr-2 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]" />
        </div>
      )}
      {activityLogs.length === 0 && (
        <div className="flex justify-center" style={{width: "384px"}}>
          <p>No Results</p>
        </div>
      )}
    </div>
  );
};

export default ActivityFeed;

export function activityFeed(
  activityLogs: ActivityLog[], 
  expanded: boolean, 
  toggleExpanded: () => void
) {
  const typeOf = (activityLog: ActivityLog) => {
      if(activityLog.typeOf === "SmsRequestReferral") {
        return(
          <span className="px-2 py-1 text-xxs text-white bg-yellow-500 rounded-full">
            Request Referral SMS
          </span>
        );
      } else if (activityLog.typeOf === "SmsApolloReceivedReferral") {
        return(
          <span className="px-2 py-1 text-xxs text-white bg-green-500 rounded-full">
            Received Apollo Referral SMS
          </span>
        );
      }
  };

  return (
    <div>
      {activityLogs.map((activityLog) => (
        <div key={activityLog.id} className="mb-3 mx-3">
          <div className="bg-white rounded border">
            <div className="flex justify-between items-center px-2 py-1 text-white bg-gray-500 rounded-t">
              <p className="text-sm py-1">
                {activityLog.reference}
              </p>
                 {
                  typeOf(activityLog)
                 }
            </div>

            <div
              className={`text-xs px-2 py-1 text-gray-700 cursor-pointer ${
                expanded ? "" : "line-clamp-2 overflow-hidden"
              }`}
              onClick={toggleExpanded}
            >
              {activityLog.metadata["message"]}
            </div>
          </div>
          <p className="flex justify-end text-xs py-1 text-gray-400">
            {moment.utc(activityLog.createdAt).local().format("h:mm:ss A DD/MM/YYYY")}
          </p>
        </div>
      ))}
    </div>
  );

}
