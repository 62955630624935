import React, { useEffect, useState, useCallback } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { CurrentUser, isSuperAdmin } from "../../services/User";
import MediaPatientAPI, { Referral } from "../../services/MediaPatient";
import { RequestReferral } from "./RequestReferral";
import store from "../../redux/store";
import AppLayout from "../layout/AppLayout";
import { useNavigate } from "react-router-dom";
import ReferralShowModal from "./ReferralShowModal";
import produce from "immer";
import ReferralListWebSocket  from "./ReferralListWebSocket";
import Pagination from "rc-pagination";
import PlusIcon from "../../assets/icons/PlusIcon";
import { ReferralOrigins } from "../../services/MediaPatient";
import {
  flexRender,
  getCoreRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import ReferralsTable from "./table/Columns";
import UserService, { User, UserResponse } from "../../services/UserService";
import { DeleteWithConfirmation } from "../shared/buttons/DeleteWithConfirmation";
import SearchForm from "../layout/SearchForm";
import { parsePhoneNumber } from "react-phone-number-input";
import ReferralListFilters from "./ReferralsListFilters";

const ReferralsList: React.FC = ()  => {
  const navigate = useNavigate();
  const { isLoading, getIdTokenClaims } = useAuth0();
  const [accessToken, setAccessToken] = useState<string | undefined>(undefined);
  const [referrals, setReferrals] = useState<Referral[] | []>([]);
  const [selectedMediaPatient, setSelectedMediaPatient] = useState<Referral | null>(null);
  const [loadingReferrals, setLoadingReferrals] = useState<boolean>(true);
  const [requestReferralModalIsOpen, setRequestReferralModalIsOpen] = useState(false);
  const [referralShowModalIsOpen, setReferralShowModalIsOpen] = useState(false);
  const [currentUser, setCurrentUser] = useState<CurrentUser | null>(null);
  const [currentStatusFilter, setCurrentStatusFilter] = useState<string>("All");
  const [currentOriginFilter, setCurrentOriginFilter] = useState<ReferralOrigins | null>(null);
  const [currentUserIdFilter, setCurrentUserIdFilter] = useState<string | null>(null);
  const [users, setUsers] = useState<User[] | null>(null);
  const [searchQuery, setSearchQuery] = useState<string | null>(null);
  const [currentDateOfBirth, setCurrentDateOfBirth] = useState<string>("");

  const [itemsPerPage] = useState<number>(10);
  const [itemOffset, setItemOffset] = useState(0);
  const [totalCount, setTotalCount] = useState<number>(0);

  const referralStatuses = store.getState().referralStatuses;

  store.subscribe(() => {
    setCurrentUser(store.getState().currentUser);
  });

  store.subscribe(() => {
    setReferrals(store.getState().referrals);
  });

  const getAccessToken = useCallback(async () => {
    try {
      const tokenClaims = await getIdTokenClaims();
      const accessToken = tokenClaims?.__raw;
      setAccessToken(accessToken);
      return accessToken;
    } catch (error) {
      console.error("Error retrieving access token:", error);
    }
  }, [getIdTokenClaims]);

  const parseSearchQuery = (query: string | null) => {
    if (query == null) {
      return null;
    }

    const parsedQuery = parsePhoneNumber(query, "AU");

    if (parsedQuery == undefined) {
      return query;
    }

    return parsedQuery.number;
  };

  const requestReferrals = useCallback(async (
    offset = 0,
    statusFilterName: string | null,
    userId: string | null,
    origin: ReferralOrigins | null,
    searchQuery: string | null,
    dateOfBirth: string | null
  ) => {
    setLoadingReferrals(true);
    if (currentUser === null) {
      return;
    }
  
    const token = await getAccessToken();
    const currentStatus = statusFilterName === "All" ? null : statusFilterName;
    const response = await MediaPatientAPI.index(
      currentUser, 
      token, 
      itemsPerPage, 
      offset, 
      currentStatus,
      userId,
      origin,
      parseSearchQuery(searchQuery),
      dateOfBirth
    );
  
    if (response.data) {
      const referrals = response.data as Referral[];
      setReferrals(referrals);
      setLoadingReferrals(false);
      setTotalCount(response.totalCount);
      store.dispatch({ type: "referralsState", payload: referrals });
    } else {
      console.error("Fetch current user failed:", response.error);
      setLoadingReferrals(false);
    }
  }, [currentUser, getAccessToken]);

  async function requestUsers() {
    if (currentUser === null) {
      return;
    }

    const token = await getAccessToken();
    const response: UserResponse = await UserService.index(currentUser, token);
    if (response.data) {
      setUsers(response.data);
      store.dispatch({ type: "usersState", payload: response.data });
    } else {
      console.error("Fetch current user failed:", response.error);
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handlePageClick = async (event: any) => {
    const newOffset = (event * itemsPerPage) - itemsPerPage;
    await setItemOffset(newOffset);
    requestReferrals(newOffset, currentStatusFilter, currentUserIdFilter, currentOriginFilter, searchQuery, currentDateOfBirth);
  };

  useEffect(() => {
    if (currentUser != null) {
      ReferralListWebSocket.getInstance(currentUser);
    }

    if (store.getState().users.length == 0) {
      requestUsers();
    }

    requestReferrals(0, currentStatusFilter, currentUserIdFilter, currentOriginFilter, searchQuery, currentDateOfBirth);
  }, [currentUser, requestReferrals]);

  if (isLoading) {
    return <div>Loading ...</div>;
  }

  async function destroyReferrals() {
    const referrals = selectedReferrals();

    return referrals.map(async (referral) => {
      await destroyReferral(referral);
    });
  }

  async function destroyReferral(referral: Referral) {
    if (currentUser === null) {
      return;
    }

    const token = await getAccessToken();
    const response = await MediaPatientAPI.delete(currentUser, token, referral.id);
    
    if (response.data !== null) {
      
      const nextState = produce(referrals, (draftState) => {
        const nextState = draftState.filter((mediaPatient) => mediaPatient.id !== referral.id);
        return nextState;
      });

      store.dispatch({ type: "referralsState", payload: nextState });
      return;
    }

    if (response.error !== null) {
      alert("Failed to delete");
    }
  }

  const openRequestReferralModal = () => { setRequestReferralModalIsOpen(true); };

  const closeRequestReferralModal = () => { 
    setRequestReferralModalIsOpen(false); 
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const showReferral = (referral: Referral) => {
    navigate("/referrals/" + referral.id);
  };

  const openReferralShowModal = (referral: Referral) => { 
    const newPath = `/referrals/${referral.id}`;
    window.history.pushState(null, "", newPath);

    setReferralShowModalIsOpen(true);
    setSelectedMediaPatient(referral);
  };

  const closeReferralShowModal = () => { 
    const newPath = "/referrals";
    window.history.pushState(null, "", newPath);
    setReferralShowModalIsOpen(false); 
  };

  const selectedReferrals = ()  => {
    const rowSelection = table.getState().rowSelection;
    const selectedIndices: string[] = Object.keys(rowSelection).filter(index => rowSelection[index]);

    const selectedReferrals: Referral[] = selectedIndices.map((index: string) => {
      return referrals[parseInt(index) + 1];
    });

    return selectedReferrals;
  };

  const pagination = () => {
    return(
      <div className="flex justify-end">
        <Pagination
          className="flex border border-gray-300 cursor-pointer rounded-lg bg-white mb-2"
          onChange={handlePageClick}
          pageSize={itemsPerPage}
          current={itemOffset / itemsPerPage + 1}
          style={{ width: "fit-content" }}
          total={totalCount}
          prevIcon={"<"}
          nextIcon={">"}
          jumpPrevIcon={".."}
          jumpNextIcon={".."}
        />
      </div>
    );
  };

  const statusFilterChange = (statusFilterName: string) => {
    setCurrentStatusFilter(statusFilterName);
    requestReferrals(0, statusFilterName, currentUserIdFilter, currentOriginFilter, searchQuery, currentDateOfBirth);
  };

  const userFilterChange = (userId: string | null) => {
    setCurrentUserIdFilter(userId);
    requestReferrals(0, currentStatusFilter, userId, currentOriginFilter, searchQuery, currentDateOfBirth);
  };

  const originFilterChange = (originFilterName: ReferralOrigins | null) => {
    setCurrentOriginFilter(originFilterName);
    requestReferrals(0, currentStatusFilter, currentUserIdFilter, originFilterName, searchQuery, currentDateOfBirth);
  };

  const handleDateChange = (date: string) => {
    setCurrentDateOfBirth(date);
    requestReferrals(0, currentStatusFilter, currentUserIdFilter, currentOriginFilter, searchQuery, date);
  };

  const table = useReactTable({
    data: referrals,
    columns: ReferralsTable.columns(
      destroyReferral,
    ),
    getCoreRowModel: getCoreRowModel(),
    enableFilters: true,
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
  });

  
  const currentUserIdFilterName = () => {
    if (currentUserIdFilter === null) {
      return "All";
    }
    const user = store.getState().users.find((user: User) => user.id === currentUserIdFilter);
    if (user === undefined) {
      return "All";
    }
    return `${user.firstName} ${user.lastName}`;
  };

  const resetFilters = () => {
    setCurrentStatusFilter("All");
    setCurrentUserIdFilter(null);
    setCurrentOriginFilter(null);
    setSearchQuery("");
    setCurrentDateOfBirth("");
    requestReferrals(0, null, null, null, null, null);
  };

  return (
    <AppLayout>
      <div>
        {
          accessToken && currentUser && <RequestReferral isOpen={requestReferralModalIsOpen} closeModal={closeRequestReferralModal} accessToken={accessToken} currentUser={currentUser} />
        }

        {
          referralShowModalIsOpen && <ReferralShowModal 
            isOpen={referralShowModalIsOpen} 
            closeModal={closeReferralShowModal} 
            referral={selectedMediaPatient}
            users={users}
          />
        }
        <section className="px-12">
          <section className="w-full mx-auto pt-8 pb-4">
            <div className="flex justify-between items-center">
              <div className="w-full lg:w-auto flex items-center mb-4 lg:mb-0">
                <h2 className="text-4xl font-bold">Referrals</h2>
                <span className="inline-block py-1 px-4 ml-2 rounded-full text-white bg-indigo-500">{totalCount} Total</span>
              </div>

              <button 
                className="md:w-auto flex items-center py-2 px-4 rounded bg-indigo-500 hover:bg-indigo-600 text-white font-medium"
                onClick={openRequestReferralModal}
              >
                <span className="inline-block mr-1">
                  <PlusIcon />
                </span>
                <p>Request referral</p>
              </button>
            </div>
          </section>

          {
            selectedReferrals().length > 0 && isSuperAdmin(currentUser) && (
              <div className="flex text-purple-600 text-sm pt-3">
                <label className="pr-1">Mass Selection:</label>
                <div>{selectedReferrals().length}</div>

                <p className="text-black pl-3">Delete All</p>
                <DeleteWithConfirmation 
                  destroy={()=> destroyReferrals()}
                  message={`Are you sure you want to delete ${selectedReferrals().length} ${selectedReferrals().length > 1 ? "referrals" : "referral"}?`}
                />
              </div>
            )
          }

          <div className="flex justify-between items-center pt-4">
            <ReferralListFilters
              resetFilters={resetFilters} 
              currentUserIdFilterName={currentUserIdFilterName}
              currentUser={currentUser}
              accessToken={accessToken}
              currentStatusFilter={currentStatusFilter}
              statusFilterChange={statusFilterChange}
              currentOriginFilter={currentOriginFilter}
              originFilterChange={originFilterChange}
              currentAssignedToFilterName={currentUserIdFilterName()}
              assignedToChange={userFilterChange}
              dateOfBirth={currentDateOfBirth}
              handleDateChange={handleDateChange}
            />

            <div>
              <div className="relative w-full">
                <SearchForm
                  searchQuery={searchQuery === null ? undefined : searchQuery}
                  setSearchQuery={setSearchQuery} 
                  requestSearch={() => {
                    requestReferrals(
                      0, 
                      null, 
                      null, 
                      null, 
                      searchQuery,
                      null
                    );
                  }}
                />
              </div>
            </div>
          </div>
          {
            referrals.length == 0 && <div>
              {
                !loadingReferrals && <div className="flex justify-center mt-8">
                  <div className="mt-8 border-t-4 bg-white border-blue-500 text-blue-900 px-4 py-3" role="alert" style={{ width: "300px" }}>
                    <div className="flex flex-col items-center text-center">
                      <div className="py-1">
                        <svg className="fill-current h-6 w-6 text-blue-500 mr-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                          <path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z"/>
                        </svg>
                      </div>
                      <p className="text-lg font-bold">Receive Your First Referral</p>
                      <p className="text-sm">Click &quot;Request Referral&quot; to get started.</p>
                    </div>
                  </div>
                </div>
              }

              {
                loadingReferrals &&
                <div className="flex justify-center pt-8">
                  <span className="inline-block h-8 w-8 animate-spin text-gray-900 rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]" />
                </div>
              }
            </div>
          }

          {
            referrals.length > 0 && (
              <div>      
                { loadingReferrals === true && (
                    <section className="py-8">
                      <div className="w-full mx-auto flex justify-center pt-8">
                        <span className="inline-block h-8 w-8 animate-spin text-gray-900 rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]" />
                      </div>
                    </section>
                  )
                }

                {
                  loadingReferrals === false && 
                  (referralStatuses?.length ?? 0) > 0 && 
                  <section className="pt-4 pb-2">
                    <div className="mb-6 bg-white rounded-lg overflow-x-auto" style={{
                      border: "1px solid #dfdfdf"
                    }}>
                      <table className="min-w-full text-left text-sm">
                        <thead className="border-b font-medium dark:border-gray-150" style={{
                          color: "#636363"
                        }}>
                          {table.getHeaderGroups().map(headerGroup => (
                            <tr key={headerGroup.id}>
                              {headerGroup.headers.map(header => (
                                <th key={header.id} className="px-6 py-4">
                                  {header.isPlaceholder
                                    ? null
                                    : flexRender(
                                        header.column.columnDef.header,
                                        header.getContext()
                                      )}
                                </th>
                              ))}
                            </tr>
                          ))}
                        </thead>
                        <tbody>
                          {
                            referrals.length > 0 &&
                            table.getRowModel().rows.map(row => (
                              <tr 
                                key={row.id} 
                                className="dark:hover:bg-gray-50 relative cursor-pointer z-0"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  openReferralShowModal(row.original);
                                }}
                              >
                                {row.getVisibleCells().map(cell => (
                                  <td key={cell.id} className="px-6 py-1">
                                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                  </td>
                                ))}
                              </tr>
                            ))
                          }
                          {
                            loadingReferrals === false && referrals.length === 0 && (
                              <div className="py-8" style={{height: "300px"}}>
                                <p className="flex justify-center text-center">{"No referrals"}</p>
                              </div>
                            )
                          }
                        </tbody>
                        <tfoot>
                          {table.getFooterGroups().map(footerGroup => (
                            <tr key={footerGroup.id}>
                              {footerGroup.headers.map(header => (
                                <th key={header.id}>
                                  {header.isPlaceholder
                                    ? null
                                    : flexRender(
                                        header.column.columnDef.footer,
                                        header.getContext()
                                      )}
                                </th>
                              ))}
                            </tr>
                          ))}
                        </tfoot>
                        
                      </table>
                    </div>
                    { pagination() }
                  </section>
                }
              </div>
            )
          }
          
        </section>
      </div>
    </AppLayout>
  );
};

export default ReferralsList;
